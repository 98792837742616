import React, { useState } from 'react';
import { useCart } from '@/context/cart/CartContext';
import Loader from '../loading-spinner';
import { cn } from '@/utils/cn';
import toast from 'react-hot-toast';
import getToken from '@/hook/getToken';
import BASE_URL from '../constants';
import { useAuth } from '@/context/AuthContext';
import { environment } from '../constants';
const AddToCart = ({
  availableForSale,
  productId,
  productName,
  productPrice,
  productBrand,
  productCategory,
  productVariant,
  className,
  iconColor,
  orderQty,
  textColor
}) => {
  const { setIsSignInOpen } = useAuth()
  const [iconStyle, setIconStyle] = useState(iconColor);
  const [adding, setAdding] = useState(false);
  const [isNotifyClicked, setIsNotifyClicked] = useState(false)
  const { addToCart } = useCart();
  const handleAdd = (productId) => {
    setAdding(true);
    addToCart(productId, orderQty).then((data) => {
      if (data) {
        if (environment !== 'prod') {
          return (
            setAdding(false)
          )
        }

        // Ensure GA4 is available before sending event
        if (typeof window !== 'undefined' && window.dataLayer) {
          window.dataLayer.push({
            event: "add_to_cart",
            ecommerce: {
              currency: "INR",
              value: productPrice * orderQty,
              content_type: 'product',
              items: [
                {
                  item_id: productId,
                  item_name: productName,
                  item_brand: productBrand,
                  item_category: productCategory,
                  item_variant: productVariant,
                  price: Number(productPrice),
                  quantity: orderQty,
                },
              ],
            },
          });
          console.log("GTM add_to_cart event sent!");
        } else {
          console.error("GTM dataLayer is not available.");
        }
        // Track event with Facebook Pixel
        if (typeof fbq !== 'undefined') {
          fbq('track', 'AddToCart', {
            content_ids: [productId],
            content_type: 'product',
          });
        }
        setAdding(false);
      } else {
        setAdding(false);
      }
    });
  };
  const handleMouseEnter = () => {
    setIconStyle('#ffffff');
  };

  const handleMouseLeave = () => {
    setIconStyle(iconColor);
  };
  const textcolor = textColor ? textColor : 'text-white'
  if (availableForSale) {
    return (
      <div className='group relative flex flex-1 items-center justify-center overflow-hidden rounded-full border border-black pl-2 font-medium text-black shadow-md transition duration-300 ease-out sm:max-w-[220px]'>
        <svg
          className='h-4 w-4'
          fill='none'
          stroke='currentColor'
          viewBox='0 0 24 24'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
            d='M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z'
          ></path>
        </svg>
        <button
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          aria-label='Add item to cart'
          onClick={() => handleAdd(productId)}
          className={`flex-1 text-xs sm:text-sm tracking-tight ${className} ${!availableForSale && "pointer-events-none bg-gray-400 text-black"
            } relative inline-flex items-center justify-center px-1 py-1 sm:px-6 sm:py-2 overflow-hidden font-medium text-black transition duration-300 ease-out rounded-full shadow-sm group`}
        >
          <span className={`absolute inset-0 flex items-center justify-center w-full h-full ${textcolor} duration-300 -translate-x-full bg-gray-300 group-hover:translate-x-0 ease`}>
            <p className="text-black">Great Choice!</p>
          </span>
          <span className={`text-xs sm:text-sm md:text-base absolute flex items-center justify-center w-full h-full ${textcolor} transition-all duration-300 transform group-hover:translate-x-full ease`}>
            Add To Cart
          </span>
          <span className='invisible relative'>Great Choice!</span>
          {adding ? <Loader className={'absolute right-2 h-4 w-4'} /> : null}
        </button>
      </div>
    );
  }

  const handleNotify = async (productId) => {
    if (!getToken()) {
      return setIsSignInOpen(true)
    }
    const res = await fetch(`${BASE_URL}/v1/notify/me`, {
      method: "POST",
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      body: JSON.stringify({
        product_id: productId
      })
    })
    const data = await res.json()
    if (res.ok) {
      setIsNotifyClicked(true)
      return toast.success(data.message)
    }
    setIsNotifyClicked(true)
    toast.success(data.message)
  }
  return (
    <div
      className={cn(
        'group relative flex flex-1 items-center justify-center overflow-hidden rounded-full font-medium text-black shadow-md transition duration-300 ease-out',
        'sm:max-w-[220px]'
      )}
    >
      <button
        aria-label="cart-notification"
        onClick={() => handleNotify(productId)}
        className={cn(
          'w-full flex-1 text-xs sm:text-sm tracking-tight',
          'relative inline-flex items-center justify-center px-1 py-1 sm:px-6 sm:py-2',
          'overflow-hidden font-medium text-black transition duration-300 ease-out',
          'rounded-full shadow-sm group hover:bg-black',
          className
        )}
      >
        <span >Notify Me</span>
        <span className="ml-2">
          {
            isNotifyClicked ? (
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 ">
                <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 text-white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0"
                />
              </svg>
            )
          }
        </span>
      </button>
    </div >
  )
};

export default AddToCart;
